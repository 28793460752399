import {BaseModule} from "./BaseModule";

class MobileMenu extends BaseModule {
    constructor() {
        super();

        this.menu = null;
    }

    init () {
        let buttons, index;

        this.menu = document.querySelector('#mMenu');

        document.querySelector('#toggleMMenu').addEventListener('click', this.toggleMenu.bind(this));

        buttons = this.menu.querySelectorAll('.nav-link');

        for (index in buttons)
            if (buttons.hasOwnProperty(index)) {
                buttons[index].addEventListener('click', this.goTo.bind(this));
            }
    }

    toggleMenu (e) {
        e.preventDefault();

        this.menu.classList.toggle('hidden');
    }

    goTo (e) {
        e.stopPropagation();

        if (e.target === e.currentTarget) {
            e.currentTarget.querySelector('a').click();
        } else {
            this.menu.classList.toggle('hidden');
        }
    }
}

export default MobileMenu;
