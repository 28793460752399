export class BaseModule {
    constructor(dependency) {
        this.dependency = null;

        if (dependency) {
            if (typeof dependency === "string") {
                this.dependency = [dependency];
            } else if (Array.isArray(dependency)) {
                this.dependency = dependency;
            }
        }
    }

    setDependencies()
    {
        let modules, key;

        if (this.dependency && arguments.length > 0) {
            modules = arguments[0];

            for (key in this.dependency)
                if (this.dependency.hasOwnProperty(key)) {
                    if (modules.hasOwnProperty(this.dependency[key])) {
                        this[this.dependency[key]] = modules[this.dependency[key]]
                    }
                }
        }
    }

    init() {}
}
