import {BaseModule} from "./BaseModule";
import Translator from "../../../lib/translate";

const confirmTokenName = 'cookieAgreement';

class CookieConfirm extends BaseModule {
    constructor() {
        super();

        this.translator = new Translator();
        this.translate  = this.translator.translate;
        this.container  = null;
    }

    init() {
        this.container = document.body.appendChild(document.createElement('DIV'));

        this.translator.changeLocale(Translator.getLocaleByAlias(document.documentElement.lang));

        if (document.cookie.search(confirmTokenName + '=yes') === -1) {
            this.render();
        }
    }

    render () {
        let badge   = this.container.appendChild(document.createElement('DIV')),
            header  = badge.appendChild(document.createElement('H2')),
            text    = badge.appendChild(document.createElement('DIV')),
            actions = badge.appendChild(document.createElement('DIV')),
            discard = actions.appendChild(document.createElement('A')),
            accept  = actions.appendChild(document.createElement('A'));

        badge.className   = 'popup__content';
        header.className  = 'popup__content-header';
        text.className    = 'popup__content-text';
        actions.className = 'popup__actions';
        discard.className = 'popup__action popup__action-discard';
        accept.className  = 'popup__action popup__action-accept';

        header.innerHTML  = this.translate('cookie.title');
        text.innerHTML    = this.translate('cookie.text');
        discard.innerHTML = this.translate('buttons.discard');
        accept.innerHTML  = this.translate('buttons.accept');

        discard.href = '#';
        accept.href  = '#';

        discard.addEventListener('click', (function (e) {
            e.preventDefault();

            this.container.innerHTML = '';
        }).bind(this));

        accept.addEventListener('click', (function (e) {
            document.cookie = confirmTokenName + '=yes; path=/; max-age=' + (3600 * 24 *365);

            this.container.innerHTML = '';
        }).bind(this));
    }
}

export default CookieConfirm;
