import flatten from "flat";
import {LOCALES} from "../constants";

const messages = {
    nav       : {
        exit       : "Exit",
        baseData   : "Basic data",
        users      : "Users",
        groups     : "Groups",
        games      : "Games",
        tasks      : "Tasks",
        game       : {
            abacus      : "Abacus",
            abacusRandom: "Abacus random",
            practice    : "Anzan",
            tutor       : "Addition and Subtraction"
        },
        myStatistic: "My stats"
    },
    user      : {
        surname           : "Surname",
        name              : "Name",
        patronymic        : "Patronymic",
        email             : "Email",
        birthDate         : "Date of Birth",
        role              : "Role",
        pupil             : "Pupil",
        teacher           : "Teacher",
        admin             : "Administrator",
        status            : "Status",
        contact           : "Phone number",
        contactName       : "Contact person",
        unconfirmed       : "Unconfirmed",
        confirmed         : "Confirmed",
        locked            : "Limited",
        banned            : "Locked",
        level             : "Level",
        studyIn           : "Studying in groups",
        studyStart        : "Start training",
        teacherContact    : "Teacher phone",
        newbie            : "newbie",
        study             : "first level",
        advanced          : "middle level",
        expert            : "advanced level",
        finished          : "training completed",
        paused            : "training suspended",
        teachesIn         : "Teaches in groups",
        notStudy          : "Not training",
        nikName           : "Game name",
        fio               : "Full name",
        notInfo           : "No information",
        changeStatusTitle : "Change of status",
        changeLevelTitle  : "Level change",
        confirmDeleteTitle: "Delete user",
        confirmDeleteText : "Are you sure you want to delete the user?",
        soundEvents       : "Voice events"
    },
    group     : {
        nameGroup           : "Group name",
        name                : "Group",
        user                : "Teacher",
        archived            : "Archived Group",
        yes                 : "Yes",
        no                  : "No",
        notInfo             : "No information",
        pupils              : "Pupils",
        teacher             : "Teacher",
        dateStart           : "Start date of education",
        changeTeacherTitle  : "Teacher change",
        confirmArchiveTitle : "Move group to archive",
        confirmArchiveText  : "Are you sure you want to move the group to the archive?",
        confirmDeleteTitle  : "Group deletion",
        confirmDeleteText   : "Are you sure you want to delete the group?",
        managementPupilTitle: "Pupil management"
    },
    task      : {
        name           : "Name",
        validTill      : "Valid till",
        createdAt      : "Creation date",
        tasksCount     : "Number of tasks",
        status         : "Status",
        taskStatus     : "Status",
        user           : "Teacher",
        notInfo        : "No information",
        assigned       : "Who is assigned",
        tasks          : "Tasks",
        teacher        : "Teacher",
        assignedPupil  : "Assign a student",
        assignedGroup  : "Assign to group",
        addTask        : "Add task",
        selectGameTitle: "Choose a game",
        deleteGameTitle: "Game deletion",
        deleteGameText : "Are you sure you want to delete the game?",
        draft          : "Draft",
        active         : "Active",
        outdated       : "Overdue",
        closed         : "Closed",
        completed      : "Completed",
        done           : "Done",
        changeStatus   : "Change status",
        deleteTaskTitle: "Deleting a task",
        deleteTaskText : "Are you sure you want to delete the task?",
        close          : "Close",
        closeTaskTitle : "Closing the task",
        closeTaskText  : "Are you sure you want to close the task?"
    },
    buttons   : {
        add            : "Add",
        edit           : "Edit",
        save           : "Save",
        cancel         : "Cancel",
        changeStatus   : "Change status",
        changeLevel    : "Change the level",
        remove         : "Delete",
        yes            : "Yes",
        no             : "No",
        changeTeacher  : "Change Teacher",
        archiveGroup   : "Move to archive",
        managementPupil: "Pupil management",
        start          : "Start",
        back           : "Back",
        pause          : "Pause",
        leaveGame      : "Leave the game",
        showResult     : "Show result",
        hideResult     : "Hide result",
        send           : "Submit",
        again          : "Try again",
        answer         : "Answer",
        next           : "Next",
        filter         : "Filter",
        reset          : "Reset",
        ok             : "Ok",
        discard        : "Discard",
        accept         : "Accept",
        newAlignment   : "New alignment"
    },
    boolean   : {
        yes: "Yes",
        no : "No"
    },
    game      : {
        speed                    : "Speed",
        randomNumberLabel        : "Random numbers",
        randomNumberDescription  : "Numbers fall randomly, starting with 0. Example: 3, 5, 1, +9, -2 ...",
        positiveNumberLabel      : "Positive numbers",
        positiveNumberDescription: "Only positive numbers starting with 0. Example: +1, +4, +6, +9, +5 ...",
        negativeNumberLabel      : "Negative numbers",
        negativeNumberDescription: "Only negative numbers, starting with 99,999 and the like, depending on the complexity. Example: -9, -2, -3, -1 ...",
        complexity               : "Complexity",
        countActions             : "Number of actions with numbers",
        enterAnswer              : "Enter your answer",
        wrong                    : "Wrong",
        success                  : "Right",
        simpleFriends            : "Just friends",
        littleFriends            : "Little friends",
        bigFriends               : "Great friends",
        family                   : "Family",
        sound                    : "Sound actions"
    },
    statistic : {
        countGames           : "Games played",
        totalWins            : "Number of wins",
        rateWins             : "Win rate",
        rateAttempts         : "Average number of attempts to win",
        lastGame             : "The last game",
        typeOfGame           : "Game type",
        startedAt            : "Start",
        completedAt          : "Ending",
        totalAttempts        : "Number of attempts",
        isSolution           : "Was the solution reviewed",
        resultGame           : "The result of the game",
        win                  : "Victory",
        loss                 : "Losing",
        systemEnded          : "Ended by system",
        taskName             : "Task name",
        ownGame              : "Free play",
        deviceCount          : "Count of known devices",
        ipCount              : "Count of known IPs",
        noneGame             : "Unknown game",
        settingsGame         : "Game settings",
        typeOfNumbers        : "Number type",
        showDetailedStatistic: "Detailed statistics",
        browser              : "Browser",
        from                 : "From",
        to                   : "To"
    },
    alert     : {
        error: {
            title: "An error has occurred!"
        }
    },
    errors    : {
        300: "An error occurred while processing the request",
        301: "The specified role does not exist",
        302: "This student is already in this group",
        303: "This student is not in this group",
        304: "This action is prohibited for this user",
        305: "Transition to this status is not possible",
        306: "Unknown game action",
        307: "There is no games in task or it is not assigned",
        308: "Error creating audio track",
        309: "Token identification error",
        310: "Token action identification error",
        311: "Token action execution error",
        312: "Could not find user by token",
        313: "The task is not associated with either a group or a user",
        400: "User is not authenticated in the system",
        401: "You are not allowed this action",
        402: "You have expired password or your email address is not verified",
        403: "You can not access this route directly",
        500: "An error has occurred! Work is already underway to eliminate it",
        501: "The requested data was not found",
        600: "This field is required",
        601: "The field value must be a string",
        602: "The field value must be a number",
        603: "Field value must be json",
        604: "The field value must be a date",
        605: "The field value must be email",
        606: "This meaning already exists",
        607: "The value is not valid for this field",
        608: "Field value contains invalid characters",
        609: "Object not found",
        610: "Data is not an array",
        611: "Data should only contain one variant",
        612: "The specified date must not be earlier than the specified",
        613: "The date \"from\" cannot be greater than the date \"to\"",
        614: "The field value is too short",
        615: "The field value is too long"
    },
    taskStatus: {
        draft    : "Draft",
        active   : "Active",
        outdated : "Overdue",
        closed   : "Closed",
        completed: "Completed"
    },
    common    : {
        notAccessCard: "You do not have access to the card",
        notDataInList: "No data to show"
    },
    cookie: {
        title: "Cookie files policy",
        text : "Our site uses Cookies. A Cookie is a small piece of text sent by our website to your browser. A Cookie helps our website to remember the details of your visit, such as your chosen language and visitor ID. This will help make your next visit to our site quick and convenient. Cookies play an important role - without them, internet use will be frustrating. By continuing to use our site you agree to the use of Cookies"
    }
};

export default {
    [LOCALES.ENGLISH]: flatten(messages)
};
