import routing from "../routing.json";

export const generate = (routName, params = {}) => {
    const route = routing[routName];

    let url;

    if (!route) {
        throw Error(`Not found route: ${routName}`);
    }

    url = route.uri;
    url = url.replace("?", "");

    if (Array.isArray(route.parameters)) {
        route.parameters.forEach(parameter => {
            if (parameter in params) {
                url = url.replace(new RegExp(`{${parameter}}`, "i"), params[parameter]);
            } else {
                throw Error(`Parameter "${parameter}" not specified`);
            }
        });
    }

    return url;
};

window.r = { generate };
