import axios from "axios";
import {BaseModule} from "./BaseModule";

class FormHandler extends BaseModule {
    constructor() {
        super(['modal', 'auth', 'preRequest']);

        this.activeForm = null;
    }

    init () {
        let forms = document.querySelectorAll('form'),
            index;

        for (index in forms)
            if (forms.hasOwnProperty(index)) {
                forms[index].addEventListener('submit', this.handle.bind(this));
            }
    }

    handle (e) {
        e.preventDefault();

        let data = {},
            input, index, value

        this.activeForm = e.currentTarget;

        input = this.activeForm.querySelectorAll('input');

        for (index in input)
            if (input.hasOwnProperty(index)) {
                value = input[index].value;

                data[input[index].name] = value !== '' ? value : null;
            }

        this.preRequest.gr.ready((function() {
            this.preRequest.gr.execute(this.preRequest.siteKey, {action: 'submit_' + this.activeForm.name}).then((function(token) {
                let config = {
                    method: "POST",
                    url   : this.activeForm.action,
                    data  : this.auth.signData({...data, rToken: token}),
                        headers: {
                            "X-Requested-With": "XMLHttpRequest",
                            "Content-Type"    : "application/json; charset=utf-8"
                        }
                    };

                axios.request(config).then(this.handleResponse.bind(this), this.fail.bind(this));
            }).bind(this));
        }).bind(this));
    }

    handleResponse (response) {
        if (response.status !== 200) {
            this.fail();
        } else if (response.data.success) {
            this.modal.popup('success');

            this.activeForm.reset();

            this.activeForm = null;
        } else if (response.data.errors && response.data.errors.length > 0) {
            let errors = response.data.errors;

            if (typeof errors[0].code === "number") {
                this.fail();
            } else {
                let index, label, input;

                for (index in errors)
                    if (errors.hasOwnProperty(index)) {
                        input = this.activeForm.querySelector('input[name="' + errors[index].param + '"]');
                        label = this.activeForm.querySelector('label[for="' + input.id + '"]');

                        label.innerHTML = label.innerHTML !== ''
                            ? label.innerHTML + ' ' + errors[index].code.toLocaleLowerCase(document.documentElement.lang)
                            : errors[index].code;

                        input.addEventListener('input', this.hideError.bind(this));

                        input.classList.add('form__error');
                    }
            }
        } else {
            this.fail();
        }
    }

    hideError (e) {
        e.currentTarget.parentNode.querySelector('label[for="' + e.currentTarget.id + '"]').innerHTML = '';

        e.currentTarget.removeEventListener('input', this.hideError);

        e.currentTarget.classList.remove('form__error');
    }

    fail () {
        this.modal.popup('error');

        this.activeForm.reset();

        this.activeForm = null;
    }
}

export default FormHandler;
