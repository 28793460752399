import flatten from "flat";
import {LOCALES} from "../constants";

const messages = {
    nav       : {
        exit       : "Вихід",
        baseData   : "Основні дані",
        users      : "Користувачі",
        groups     : "Групи",
        tasks      : "Завдання",
        games      : "Ігри",
        game       : {
            abacus      : "Рахівниця",
            abacusRandom: "Рахівниця random",
            practice    : "Анзан",
            tutor       : "Додавання та віднімання"
        },
        myStatistic: "Моя статистика"
    },
    user      : {
        surname           : "Прізвище",
        name              : "Ім'я",
        patronymic        : "По-батькові",
        email             : "Email",
        birthDate         : "Дата народження",
        role              : "Роль",
        pupil             : "Учень",
        teacher           : "Вчитель",
        admin             : "Адміністратор",
        status            : "Статус",
        contact           : "Номер телефону",
        contactName       : "Контактна особа",
        unconfirmed       : "Непідтверджений",
        confirmed         : "Підтверджений",
        locked            : "Обмежений",
        banned            : "Заблокований",
        level             : "Рівень",
        studyIn           : "Навчається в групах",
        studyStart        : "Старт навчання",
        teacherContact    : "Телефон вчителя",
        newbie            : "новачок",
        study             : "початковий рівень",
        advanced          : "середній рівень",
        expert            : "просунутий рівень",
        finished          : "навчання завершено",
        paused            : "навчання призупинено",
        teachesIn         : "Навчає в групах",
        notStudy          : "Не минає навчання",
        nikName           : "Ім'я гравця",
        fio               : "ПІБ",
        notInfo           : "Немає інформації",
        changeStatusTitle : "Зміна статусу",
        changeLevelTitle  : "Зміна рівня",
        confirmDeleteTitle: "Видалення користувача",
        confirmDeleteText : "Ви впевнені що хочете видалити користувача?",
        soundEvents       : "Озвучувати події"
    },
    group     : {
        nameGroup           : "Найменування групи",
        name                : "Група",
        user                : "Вчитель",
        archived            : "Група в архіві",
        yes                 : "Так",
        no                  : "Ні",
        notInfo             : "Немає інформації",
        pupils              : "Учні",
        teacher             : "Вчитель",
        dateStart           : "Дата початку навчання",
        changeTeacherTitle  : "Зміна вчителя",
        confirmArchiveTitle : "Переміщення групи в архів",
        confirmArchiveText  : "Ви впевнені що хочете перемістити групу в архів?",
        confirmDeleteTitle  : "Видалення групи",
        confirmDeleteText   : "Ви впевнені що хочете видалити групу?",
        managementPupilTitle: "Управління учнями"
    },
    task      : {
        name           : "Найменування",
        validTill      : "Діє до",
        createdAt      : "Дата створення",
        tasksCount     : "Кількість завдань",
        status         : "Статус",
        taskStatus     : "Статус",
        user           : "Вчитель",
        notInfo        : "Немає інформації",
        assigned       : "Кому призначено",
        tasks          : "Завдання",
        teacher        : "Вчитель",
        assignedPupil  : "Призначити учневі",
        assignedGroup  : "Призначити групі",
        addTask        : "Додати завдання",
        selectGameTitle: "Виберіть гру",
        deleteGameTitle: "Видалення гри",
        deleteGameText : "Ви впевнені що хочете видалити гру?",
        draft          : "Чернетка",
        active         : "Активний",
        outdated       : "Прострочений",
        closed         : "закритий",
        completed      : "Завершений",
        done           : "Виконано",
        changeStatus   : "Змінити статус",
        deleteTaskTitle: "Видалення завдан",
        deleteTaskText : "Ви впевнені що хочете видалити завдання?",
        close          : "Закрити",
        closeTaskTitle : "Закриття завдання",
        closeTaskText  : "Ви впевнені що хочете закрити завдання?"
    },
    buttons   : {
        add            : "Додати",
        edit           : "Редагувати",
        save           : "Зберегти",
        cancel         : "Відміна",
        changeStatus   : "Змінити статус",
        changeLevel    : "Змінити рівень",
        remove         : "Видалити",
        yes            : "Так",
        no             : "Ні",
        changeTeacher  : "Змінити вчителя",
        archiveGroup   : "Перемістити в архів",
        managementPupil: "Управління учнями",
        start          : "Старт",
        back           : "Назад",
        pause          : "Пауза",
        leaveGame      : "Покинути гру",
        showResult     : "Показати результат",
        hideResult     : "Приховати результат",
        send           : "Відправити",
        again          : "Спробувати ще раз",
        answer         : "Відповідь",
        next           : "Наступне",
        filter         : "Фільтрувати",
        reset          : "Скинути",
        ok             : "Ok",
        discard        : "Закрити",
        accept         : "Прийняти",
        newAlignment   : "Новий розклад"
    },
    boolean   : {
        yes: "Так",
        no : "Ні"
    },
    game      : {
        speed                    : "Швидкість",
        randomNumberLabel        : "Випадкові числа",
        randomNumberDescription  : "Числа випадають випадковим чином, починаючи з 0. Приклад: 3, 5, 1, +9, -2 ...",
        positiveNumberLabel      : "Положительные числа",
        positiveNumberDescription: "Тільки додатні числа, починаючи з 0. Приклад: +1, +4, +6, +9, +5 ...",
        negativeNumberLabel      : "Отрицательные числа",
        negativeNumberDescription: "Тільки від’ємні числа, починаючи з -99,999 тощо, в залежності від складності. Приклад: -9, 2, 3, -1 ...",
        complexity               : "Складність",
        countActions             : "Кількість дій з числами",
        enterAnswer              : "Введіть відповідь",
        wrong                    : "Неправильно",
        success                  : "Правильно",
        simpleFriends            : "Просто друзі",
        littleFriends            : "Маленькі друзі",
        bigFriends               : "Великі друзі",
        family                   : "Сім'я",
        sound                    : "Озвучувати дії"
    },
    statistic : {
        countGames           : "Зіграно ігор",
        totalWins            : "Кількість перемог",
        rateWins             : "Відсоток перемог",
        rateAttempts         : "Середнє число спроб для виграшу",
        lastGame             : "Остання гра",
        typeOfGame           : "Тип гри",
        startedAt            : "Початок",
        completedAt          : "Закінчення",
        totalAttempts        : "Кількість спроб",
        isSolution           : "Чи було переглянуто рішення",
        resultGame           : "Результат гри",
        win                  : "Перемога",
        loss                 : "Програш",
        systemEnded          : "Завершено системою",
        taskName             : "Завдання",
        ownGame              : "Самостійна гра",
        deviceCount          : "Кількість використаних пристроїв",
        ipCount              : "Кількість використаних IP адрес",
        noneGame             : "Невідома гра",
        settingsGame         : "Налаштування гри",
        typeOfNumbers        : "Тип чисел",
        showDetailedStatistic: "Детальна статистика",
        browser              : "Браузер",
        from                 : "Від",
        to                   : "До"
    },
    alert     : {
        error: {
            title: "Виникла помилка!"
        }
    },
    errors    : {
        300: "Сталася помилка при обробці запиту",
        301: "Зазначеною ролі не існує",
        302: "Цей учень вже входить в цю групу",
        303: "Цього учня немає в цій групі",
        304: "Ця дія заборонено даному користувачеві",
        305: "Перехід в цей статус неможливий",
        306: "Невідоме дію з грою",
        307: "Дане завдання не містить ігор або ні на кого не призначено",
        308: "Помилка створення звукової доріжки",
        309: "Помилка ідентифікації токена",
        310: "Помилка ідентифікації дії по токені",
        311: "Помилка виконання дії токена",
        312: "Не вдалося знайти користувача за токені",
        313: "Завдання не пов'язано ні з групою ні з користувачем",
        400: "Нема аутенфіцірован в системі",
        401: "Вам заборонено дана дія",
        402: "Закінчився термін дії паролю або потрібно підтвердити адресу електронної пошти",
        403: "Ви не можете перейти за цією адресою",
        500: "Виникла помилка! Уже ведуться роботи по її усуненню",
        501: "Запитувані данни не знайдені",
        600: "Поле обов'язково до заповнення",
        601: "Значення поля повинно бути рядком",
        602: "Значення поля повинно бути числом",
        603: "Значніє поля має бути json",
        604: "Значення поля повинно бути датою",
        605: "Значення поля повинно бути email",
        606: "Таке значення вже існує",
        607: "Значення не припустимо для даного поля",
        608: "Значення поля містить неприпустимі символи",
        609: "Не вдалося знайти об'єкт",
        610: "Дані не є масивом",
        611: "Дані повинні містити тільки один варіант",
        612: "Задана дата не повинна бути раніше зазначеної",
        613: "Дата \"від\" не може бути більше дати \"до\"",
        614: "Значення поля занадто коротке",
        615: "Значення поля задовге"
    },
    taskStatus: {
        draft    : "Чернетка",
        active   : "Активний",
        outdated : "Прострочений",
        closed   : "закритий",
        completed: "Завершений"
    },
    common    : {
        notAccessCard: "У Вас немає доступу до картки",
        notDataInList: "Немає даних для відображення"
    },
    cookie: {
        title: "Використання файлів Cookie",
        text : "Наш сайт використовує Cookie. Cookie - це невеликий фрагмент тексту, що відправляється нашим сайтом Вашому браузеру. Cookie допомагає нашому сайту запам'ятати деталі вашого відвідування, такі як вибрана мова та ідентифікатор відвідувача. Це допоможе зробити Ваше наступні відвідування нашого сайту швидким і зручним. Cookie грають важливу роль - без них використання інтернету буде розчаровувати. Продовжуючи використовувати наш сайт Ви підтверджуєте свою згоду на використання файлів Cookie"
    }
};

export default {
    [LOCALES.UKRAINE]: flatten(messages),
};
