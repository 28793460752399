import {BaseModule} from "./BaseModule";

class Accordion extends BaseModule {
    constructor() {
        super();

        this.bellows = null;
    }

    init () {
        let piano = document.querySelectorAll('.question__header'),
            key;

        for (key in piano)
            if (piano.hasOwnProperty(key)) {
                piano[key].addEventListener('click', this.play.bind(this));
            }

        this.bellows = document.querySelector('.slide-questions');
    }

    play (e) {
        e.preventDefault();

        let self       = e.currentTarget.parentNode,
            fold       = self.querySelector('.question__answer'),
            pianoKey   = self.querySelector('.question__header-action'),
            onsideFold = this.bellows.querySelector('.question__answer.stretched'),
            onsideKey  = this.bellows.querySelector('.question__header-action.stretched');

        if (onsideFold && onsideKey) {
            onsideKey.classList.remove('stretched');
            onsideKey.classList.add('squeezed');

            onsideFold.classList.remove('stretched');
            onsideFold.classList.add('squeezed');

            if (onsideFold === fold) {
                return;
            }
        }

        pianoKey.classList.add('stretched');
        pianoKey.classList.remove('squeezed');

        fold.classList.add('stretched');
        fold.classList.remove('squeezed');
    }
}

export default Accordion;
