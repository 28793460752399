import Accordion from "./components/Accordion";
import Auth from "./components/Auth";
import Carousel from "./components/Carousel";
import MobileMenu from "./components/MobileMenu";
import Modal from "./components/Modal";
import LocaleSwitcher from "./components/LocaleSwitcher";
import FormHandler from "./components/FormHandler";
import PreRequest from "./components/PreRequest";
import CookieConfirm from "./components/CookieConfirm";

let modules = {
    accordion    : new Accordion(),
    auth         : new Auth(),
    carousel     : new Carousel(),
    cookieConfirm: new CookieConfirm(),
    formHandler  : new FormHandler(),
    localeSwitch : new LocaleSwitcher(),
    mobileMenu   : new MobileMenu(),
    modal        : new Modal(),
    preRequest   : new PreRequest()
};

export function startApp() {
    let index, module;

    for (index in modules)
        if (modules.hasOwnProperty(index)) {
            module = modules[index];

            if (module.dependency && module.dependency.length > 0) {
                module.setDependencies(modules);
            }

            module.init();
        }
}

export function singleModule(key, hot) {
    if (modules.hasOwnProperty(key)) {
        if (modules[key].dependency && modules[key].dependency.length > 0) {
            modules[key].setDependencies(modules);
        }

        if (!!hot) {
            modules[key].init();
        }

        return modules[key];
    }

    throw 'Unknown module ' + key;
}
