import flatten from "flat";
import {LOCALES} from "../constants";

const messages = {
    nav       : {
        exit       : "Выход",
        baseData   : "Основные данные",
        users      : "Пользователи",
        groups     : "Группы",
        tasks      : "Задачи",
        games      : "Игры",
        game       : {
            abacus      : "Счеты",
            abacusRandom: "Счеты random",
            practice    : "Анзан",
            tutor       : "Прибавление и вычитание"
        },
        myStatistic: "Моя статистика"
    },
    user      : {
        surname           : "Фамилия",
        name              : "Имя",
        patronymic        : "Отчество",
        email             : "Email",
        birthDate         : "Дата рождения",
        role              : "Роль",
        pupil             : "Ученик",
        teacher           : "Учитель",
        admin             : "Администратор",
        status            : "Статус",
        contact           : "Номер телефона",
        contactName       : "Контактное лицо",
        unconfirmed       : "Неподтвержденный",
        confirmed         : "Подтвержденный",
        locked            : "Ограниченный",
        banned            : "Заблокированный",
        level             : "Уровень",
        studyIn           : "Обучается в группах",
        studyStart        : "Старт обучения",
        teacherContact    : "Телефон учителя",
        newbie            : "новичок",
        study             : "начальный уровень",
        advanced          : "средний уровень",
        expert            : "продвинутый уровень",
        finished          : "обучение завершено",
        paused            : "обучение приостановлено",
        teachesIn         : "Обучает в группах",
        notStudy          : "Не проходит обучение",
        nikName           : "Игровое имя",
        fio               : "ФИО",
        notInfo           : "Нет информации",
        changeStatusTitle : "Смена статуса",
        changeLevelTitle  : "Смена уровня",
        confirmDeleteTitle: "Удаление пользователя",
        confirmDeleteText : "Вы уверены что хотите удалить пользователя?",
        soundEvents       : "Озвучивать события"
    },
    group     : {
        nameGroup           : "Наименование группы",
        name                : "Группа",
        user                : "Учитель",
        archived            : "Группа в архиве",
        yes                 : "Да",
        no                  : "Нет",
        notInfo             : "Нет информации",
        pupils              : "Ученики",
        teacher             : "Учитель",
        dateStart           : "Дата начала обучения",
        changeTeacherTitle  : "Смена учителя",
        confirmArchiveTitle : "Перемещение группы в архив",
        confirmArchiveText  : "Вы уверены что хотите переместить группу в архив?",
        confirmDeleteTitle  : "Удаление группы",
        confirmDeleteText   : "Вы уверены что хотите удалить группу?",
        managementPupilTitle: "Управление учениками"
    },
    task      : {
        name           : "Наименование",
        validTill      : "Действует до",
        createdAt      : "Дата создания",
        tasksCount     : "Количество задач",
        status         : "Статус",
        taskStatus     : "Статус",
        user           : "Учитель",
        notInfo        : "Нет информации",
        assigned       : "Кому назначено",
        tasks          : "Задачи",
        teacher        : "Учитель",
        assignedPupil  : "Назначить ученику",
        assignedGroup  : "Назначить группе",
        addTask        : "Добавить игру",
        selectGameTitle: "Выберите игру",
        deleteGameTitle: "Удаление игры",
        deleteGameText : "Вы уверены что хотите удалить игру?",
        draft          : "Черновик",
        active         : "Активный",
        outdated       : "Просроченный",
        closed         : "Закрытый",
        completed      : "Завершенный",
        done           : "Выполнено",
        changeStatus   : "Сменить статус",
        deleteTaskTitle: "Удаление задачи",
        deleteTaskText : "Вы уверены что хотите удалить задачу?",
        close          : "Закрыть",
        closeTaskTitle : "Закрытие задачи",
        closeTaskText  : "Вы уверены что хотите закрыть задачу?"
    },
    buttons   : {
        add            : "Добавить",
        edit           : "Редактировать",
        save           : "Сохранить",
        cancel         : "Отмена",
        changeStatus   : "Сменить статус",
        changeLevel    : "Сменить уровень",
        remove         : "Удалить",
        yes            : "Да",
        no             : "Нет",
        changeTeacher  : "Сменить учителя",
        archiveGroup   : "Переместить в архив",
        managementPupil: "Управление учениками",
        start          : "Старт",
        back           : "Назад",
        pause          : "Пауза",
        leaveGame      : "Покинуть игру",
        showResult     : "Показать результат",
        hideResult     : "Скрыть результат",
        send           : "Отправить",
        again          : "Попробовать ещё раз",
        answer         : "Ответ",
        next           : "Следующее",
        filter         : "Фильтровать",
        reset          : "Сбросить",
        ok             : "Ok",
        discard        : "Закрыть",
        accept         : "Принять",
        newAlignment   : "Новый расклад"
    },
    boolean   : {
        yes: "Да",
        no : "Нет"
    },
    game      : {
        speed                    : "Скорость",
        randomNumberLabel        : "Случайные числа",
        randomNumberDescription  : "Числа выпадают случайным образом, начиная с 0. Пример: 3, 5, 1, +9, -2 ...",
        positiveNumberLabel      : "Положительные числа",
        positiveNumberDescription: "Только положительные числа, начиная с 0. Пример: +1, +4, +6, +9, +5 ...",
        negativeNumberLabel      : "Отрицательные числа",
        negativeNumberDescription: "Только отрицательные числа, начиная с -99,999 и тому подобное, в зависимости от сложности. Пример: -9, 2, 3, -1 ...",
        complexity               : "Сложность",
        countActions             : "Количество действий с числами",
        enterAnswer              : "Введите ответ",
        wrong                    : "Неправильно",
        success                  : "Правильно",
        simpleFriends            : "Просто друзья",
        littleFriends            : "Маленькие друзья",
        bigFriends               : "Большие друзья",
        family                   : "Семья",
        sound                    : "Озвучивать действия"
    },
    statistic : {
        countGames           : "Сыграно игр",
        totalWins            : "Количество побед",
        rateWins             : "Процент побед",
        rateAttempts         : "Среднее число попыток для выигрыша",
        lastGame             : "Последняя игра",
        typeOfGame           : "Тип игры",
        startedAt            : "Начало",
        completedAt          : "Окончание",
        totalAttempts        : "Количество попыток",
        isSolution           : "Было ли просмотрено решение",
        resultGame           : "Результат игры",
        win                  : "Победа",
        loss                 : "Проигрыш",
        systemEnded          : "Завершено системой",
        taskName             : "Задание",
        ownGame              : "Самостоятельная игра",
        deviceCount          : "Количество использованных устройств",
        ipCount              : "Количество использованных IP адресов",
        noneGame             : "Неизвестная игра",
        settingsGame         : "Настройки игры",
        typeOfNumbers        : "Тип чисел",
        showDetailedStatistic: "Детальная статистика",
        browser              : "Браузер",
        from                 : "От",
        to                   : "До"
    },
    alert     : {
        error: {
            title: "Произошла ошибка!"
        }
    },
    errors    : {
        300: "Произошла ошибка при обработке запроса",
        301: "Указанной роли не существует",
        302: "Этот ученик уже входит в эту группу",
        303: "Этого ученика нет в этой группе",
        304: "Это действие запрещено данному пользователю",
        305: "Переход в этот статус невозможен",
        306: "Неизвестное действие с игрой",
        307: "Данное задание не содержит игр или ни на кого не назначено",
        308: "Ошибка создания звуковой дорожки",
        309: "Ошибка идентификации токена",
        310: "Ошибка идентификации действия по токену",
        311: "Ошибка выполнения действия токена",
        312: "Не удалось найти пользователя по токену",
        313: "Задание не связано ни с группой ни с пользователем",
        400: "Пользователь не аутенфицирован в системе",
        401: "Вам не разрешено данное действие",
        402: "Истек срок действия Вашего пароля или требуется подтвердить адрес электронной почты",
        403: "Вы не можете перейти по этому адресу",
        500: "Произошла ошибка! Уже ведуться работы по её устранению",
        501: "Запрашиваемые данны не найдены",
        600: "Поле обязательно к заполнению",
        601: "Значение поля должно быть строкой",
        602: "Значение поля должно быть числом",
        603: "Значние поля должно быть json",
        604: "Значение поля должно быть датой",
        605: "Значение поля должно быть email",
        606: "Такое значение уже существует",
        607: "Значение не допустимо для данного поля",
        608: "Значение поля содержит недопустимые символы",
        609: "Не найден объект",
        610: "Данные не являются массивом",
        611: "Данные должны содержать только один вариант",
        612: "Заданная дата не должна быть раньше указанной",
        613: "Дата \"от\" не может быть больше даты \"до\"",
        614: "Значение поля слишком короткое",
        615: "Значение поля слишком длинное"
    },
    taskStatus: {
        draft    : "Черновик",
        active   : "Активный",
        outdated : "Просроченный",
        closed   : "Закрытый",
        completed: "Завершенный"
    },
    common    : {
        notAccessCard: "У Вас нет доступа к карточке",
        notDataInList: "Нет данных для отображения"
    },
    cookie    : {
        title: "Использование файлов Cookie",
        text : "Наш сайт использует Cookie. Cookie - это небольшой фрагмент текста, отправляемый нашим сайтом Вашему браузеру. Cookie помогает нашему сайту запомнить детали вашего посещения, такие как выбранный язык и идентификатор посетителя. Это поможет сделать Ваше следующие посещение нашего сайта быстрым и удобным. Cookie играют важную роль - без них использование интернета будет разочаровывать. Продолжая использовать наш сайт Вы подтверждаете свое согласие на использование файлов Cookie"
    }
};

export default {
    [LOCALES.RUSSIAN]: flatten(messages)
};
