import messages from '../i18n/messages';
import {LOCALES} from "../i18n";

const defaultLocale    = 'ENGLISH';
const availableLocales = Object.keys(LOCALES);

const localeAliases = {
    RUSSIAN: ['ru', 'by', 'ru-ru', 'ru-by', 'by-by', 'russian', 'rus', 'bel'],
    UKRAINE: ['uk', 'ua', 'uk-ua', 'ua-uk', 'ukraine', 'ukr'],
    ENGLISH: ['en', 'us', 'en-us', 'en-uk','en-au', 'english', 'eng']
};

let currentMessages;

class Translator {
    constructor(locale) {
        this.changeLocale(locale)
    }

    translate(message, bail) {
        if (currentMessages.hasOwnProperty(message)) {
            return currentMessages[message];
        } else if (typeof bail === 'undefined' || bail) {
            return message;
        }

        throw 'Message key ' + message + ' not found in translatable messages';
    }

    changeLocale(locale) {
        if (!availableLocales.includes(locale)) {
            locale = defaultLocale;
        }

        currentMessages = messages[LOCALES[locale]];
    }

    static getAvailableLocales() {
        return availableLocales;
    }

    static getLocaleByAlias(alias) {
        if (alias && typeof alias === 'string') {
            alias = alias.toLowerCase();

            for (let index in localeAliases)
                if (localeAliases.hasOwnProperty(index)) {
                    if (localeAliases[index].includes(alias)) {
                        return index;
                    }
                }
        }

        return defaultLocale;
    }
}

export default Translator;
