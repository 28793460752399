import {BaseModule} from "./BaseModule";

const SWIPE_INDICATION = 40;
const SWIPE_AREA       = 20;

class Carousel extends BaseModule {
    constructor() {
        super();

        this.gallery      = null;
        this.collection   = null;
        this.pagination   = null;
        this.countedItems = 0;
        this.currentItem  = 0;
        this.touchX       = null;
        this.touchY       = null;
    }

    init () {
        let page, wrap;

        this.gallery      = document.querySelector('#gallery');
        this.collection   = this.gallery.querySelectorAll('.carousel__content .carousel__tile');
        this.pagination   = this.gallery.querySelectorAll('.carousel__pagination .carousel__pagination-item');
        this.countedItems = this.collection.length - 1;

        this.collection[0].classList.add('current');
        this.pagination[0].classList.add('current');

        this.gallery.querySelector('.action__left').addEventListener('click', this.prevPage.bind(this));
        this.gallery.querySelector('.action__right').addEventListener('click', this.nextPage.bind(this));

        wrap = this.gallery.querySelector('.carousel__wrap');

        wrap.addEventListener('touchstart', this.touched.bind(this));
        wrap.addEventListener('touchcancel', this.swipe.bind(this));

        for (page in this.pagination)
            if (this.pagination.hasOwnProperty(page)) {
                this.pagination[page].addEventListener('click', this.switchTo.bind(this));

                this.pagination[page].dataset.page = page;
            }
    }

    nextPage (e) {
        e.preventDefault();

        let base = this.getBase(),
            next = base + this.currentItem;

        if (next > this.countedItems) {
            next = 0;
        }

        this.navigate(next);
    }

    prevPage (e) {
        e.preventDefault();

        let base = this.getBase(),
            next = this.currentItem - base;

        if (next < 0) {
            next = base + next === 0
                ? Math.floor(this.countedItems / base) * base
                : 0;
        }

        this.navigate(next);
    }

    touched(e) {
        this.touchX = e.touches[0].clientX;
        this.touchY = e.touches[0].clientY;
    }

    swipe(e) {
        let endX        = e.touches[0].clientX,
            endY        = e.touches[0].clientY,
            swipeLength = this.touchX - endX;

        if (Math.abs(this.touchY - endY) <= SWIPE_AREA && Math.abs(swipeLength) >= SWIPE_INDICATION) {
            if (swipeLength > 0) {
                this.prevPage(e);
            } else {
                this.nextPage(e);
            }
        }

        this.touchX = null;
        this.touchY = null;
    }

    switchTo (e) {
        e.preventDefault();

        this.navigate(e.currentTarget.dataset.page)
    }

    navigate (destination) {
        this.collection[this.currentItem].classList.remove('current');
        this.pagination[this.currentItem].classList.remove('current');

        this.currentItem = destination;

        this.collection[destination].classList.add('current');
        this.pagination[destination].classList.add('current');
    }

    getBase () {
        return 100 / parseInt(getComputedStyle(this.collection[this.currentItem]).flexBasis);
    }
}

export default Carousel;
