import {BaseModule} from "./BaseModule";
import axios from "axios";
import {generate} from "../../../lib/routing";

const TOKEN_LIFETIME = 7195000;

class Auth extends BaseModule {
    constructor() {
        super('modal');

        this.token         = null;
        this.intervalToken = null;
        this.timeoutToken  = null;
        this.stamp         = null;
    }

    init () {
        let links = document.querySelectorAll('.nav-item a.nav__logout'),
            token = document.head.querySelector('meta[name="csrf-token"]'),
            index;

        this.token = token.content;
        this.stamp = new Date();

        this.lifecycle();

        document.head.removeChild(token);

        for (index in links)
            if (links.hasOwnProperty(index)) {
                links[index].addEventListener('click', this.logout.bind(this));
            }

        document.addEventListener("visibilitychange", (function() {
            if (document.visibilityState === 'visible') {
                let left = (new Date()) - this.stamp;

                if (left > TOKEN_LIFETIME) {
                    this.renewToken();

                    this.lifecycle();
                } else {
                    this.timeoutToken = setTimeout((function () {
                        this.renewToken();

                        this.lifecycle();
                    }).bind(this), left);
                }
            } else {
                if (this.intervalToken) {
                    clearInterval(this.intervalToken);
                }

                if (this.timeoutToken) {
                    clearTimeout(this.timeoutToken);
                }
            }
        }).bind(this));
    }

    lifecycle () {
        if (this.intervalToken) {
            clearInterval(this.intervalToken);
        }

        if (this.timeoutToken) {
            clearTimeout(this.timeoutToken);
        }

        this.intervalToken = setInterval(this.renewToken.bind(this), TOKEN_LIFETIME);
    }

    logout (e) {
        e.preventDefault();

        let config = {
                url    : e.currentTarget.href,
                method : "POST",
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type"    : "application/json; charset=utf-8"
                },
                data: this.signData({})
            };

        axios.request(config).then(this.handleLogoutResponse.bind(this), this.fail.bind(this));
    }

    renewToken () {
        let config = {
            url    : generate('renewToken'),
            method : "GET",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type"    : "application/json; charset=utf-8"
            }
        };

        axios.request(config).then(
            (function (response) {
                if (response.status === 200 && response.data.success) {
                    this.token = response.data.data.token;
                    this.stamp = new Date();
                } else {
                    location.reload();
                }
            }).bind(this),
            () => location.reload()
        );
    }

    signData (data) {
        if (typeof data == "object") {
            data._token = this.token
        }

        return data;
    }

    handleLogoutResponse (response) {
        if (response.status !== 200) {
            this.fail();
        } else if (response.data.success) {
            location.reload();
        } else {
            this.fail();
        }
    }

    fail () {
        this.modal.popup('error');
    }
}

export default Auth;
