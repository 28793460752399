import axios from "axios";
import {BaseModule} from "./BaseModule";

class LocaleSwitcher extends BaseModule {
    constructor() {
        super('modal')

        this.localeMenu = null;
    }

    init () {
        let switches = document.querySelectorAll('.nav-locale__switch a'),
            locales  = document.querySelectorAll('.nav-locale__types a'),
            index;

        this.localeMenu = document.querySelectorAll('.nav-locale__types');

        for (index in switches)
            if (switches.hasOwnProperty(index)) {
                switches[index].addEventListener('click', this.togglePanel.bind(this));
            }

        for (index in locales)
            if (locales.hasOwnProperty(index)) {
                locales[index].addEventListener('click', this.changeLocale.bind(this));
            }
    }

    togglePanel (e) {
        e.preventDefault();

        let index;

        for (index in this.localeMenu)
            if (this.localeMenu.hasOwnProperty(index)) {
                this.localeMenu[index].classList.toggle('hidden');
            }
    }

    changeLocale (e) {
        e.preventDefault();

        let config = {
            url    : e.currentTarget.href,
            method : "GET",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type"    : "application/json; charset=utf-8"
            }
        };

        axios.request(config).then(this.success.bind(this), this.fail.bind(this));
    }

    success (response) {
        if (response.status !== 200) {
            this.fail();
        } else if (response.data.success) {
            location.reload();
        } else {
            this.fail();
        }
    }

    fail () {
        this.modal.popup('error')
    }
}

export default LocaleSwitcher;
