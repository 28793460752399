import {BaseModule} from "./BaseModule";

const SERVICE_MISSING  = 'stub-service-missing';
const SERVICE_DISABLED = 'stub-service-disabled';

class PreRequest extends BaseModule {
    constructor() {
        super();

        this.gr      = null;
        this.siteKey = null;
    }

    init() {
        let token = document.head.querySelector('meta[name="site-key"]');

        this.siteKey = token.content;
        this.gr      = window.grecaptcha ? window.grecaptcha : this.stub();

        document.head.removeChild(token);
    }

    stub() {
        let type = this.siteKey === '' ? SERVICE_DISABLED : SERVICE_MISSING,
            stub = {
                callback: null,
                ready: (callback) => stub.callback = callback,
                execute: () => {return {then: request => request(type)}}
            };

        return stub;
    }
}

export default PreRequest;
