import {BaseModule} from "./BaseModule";

const MODAL_HIDE_TIMEOUT = 750;

class Modal extends BaseModule {
    constructor() {
        super();

        this.active  = null;
        this.overlay = null;
        this.wrap    = null;
        this.modals  = {};
        this.header  = null;
        this.ids     = ['signup', 'success', 'error'];
    }

    init () {
        let signInButtons = document.querySelectorAll('.button-action.button-signup'),
            index;

        this.overlay = document.querySelector('.overlay');
        this.wrap    = document.querySelector('.filer__wrap');
        this.header  = this.overlay.querySelector('.modal__header-text');

        for (index in this.ids)
            if (this.ids.hasOwnProperty(index)) {
                this.modals[this.ids[index]] = this.overlay.querySelector('#' + this.ids[index]);
            }

        for (index in signInButtons)
            if (signInButtons.hasOwnProperty(index)) {
                signInButtons[index].addEventListener('click', function (e) {
                    e.preventDefault();

                    this.popup('signup');
                }.bind(this));
            }

        this.overlay.querySelector('.modal__header-icon').addEventListener('click', this.hide.bind(this));

        this.overlay.addEventListener(
            'click',
            function (e) {
                if (e.currentTarget === e.target) {
                    e.stopPropagation();

                    this.hide();
                }
            }.bind(this)
        );
    }

    popup (target, header) {
        let fresh = true,
            randomItems, randomItem, form;

        if (!this.modals.hasOwnProperty(target)) {
            return;
        }

        this.header.innerHTML = header ? header : '';

        if (this.active) {
            this.active.classList.add('hidden');

            randomItem = this.active.querySelector('.popup-random:not(.hidden)')
            form       = this.active.querySelector('form');

            if (randomItem) {
                randomItem.classList.add('hidden');
            }

            if (form) {
                form.reset();
            }

            fresh = false;
        }

        this.active = this.modals[target];
        randomItems = this.active.querySelectorAll('.popup-random');

        if (randomItems.length) {
            randomItems[Math.floor(Math.random() * randomItems.length)].classList.remove('hidden');
        }

        this.active.classList.remove('hidden');

        if (fresh) {
            this.overlay.classList.remove('hidden');

            document.body.classList.add('scroll__hidden');

            this.overlay.classList.remove('to-transparent');
            this.overlay.classList.add('to-visible');

            this.wrap.classList.remove('to-normal_color');
            this.wrap.classList.add('to-mono_chrome');
        }
    }

    hide () {
        document.body.classList.remove('scroll__hidden');

        this.overlay.classList.remove('to-visible');
        this.overlay.classList.add('to-transparent');

        this.wrap.classList.remove('to-mono_chrome');
        this.wrap.classList.add('to-normal_color');

        setTimeout(function () {
            let randomItem = this.active.querySelector('.popup-random:not(.hidden)'),
                form       = this.active.querySelector('form');

            if (randomItem) {
                randomItem.classList.add('hidden');
            }

            if (form) {
                form.reset();
            }

            this.active.classList.add('hidden');
            this.overlay.classList.add('hidden');

            this.active = null;
        }.bind(this), MODAL_HIDE_TIMEOUT);
    }
}

export default Modal;
